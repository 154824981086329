<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="科目">
              <a-select
                allow-clear
                v-model="form.courseSubject"
                placeholder="科目"
                style="width: 100%"
                @change="subjectChange"
              >
                <a-select-option value="CHINESE"> 中文 </a-select-option>
                <a-select-option value="MATH"> 数学 </a-select-option>
                <a-select-option value="ENGLISH"> 英文</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="班级名称">
              <a-input allow-clear v-model="form.className" @pressEnter="getListData(1)" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="班型">
              <a-select allow-clear v-model="form.classType">
                <a-select-option v-for="item in scheduleOptions.classType" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="课程类型">
              <a-select allow-clear v-model="form.courseType">
                <a-select-option v-for="item in scheduleOptions.courseType" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="状态">
              <a-select allow-clear v-model="form.classStatus">
                <a-select-option v-for="item in scheduleOptions.classStatus" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="课程名称">
              <a-select
                allow-clear
                show-search
                v-model="form.courseId"
                :dropdown-match-select-width="false"
                :filter-option="filterOption"
                option-filter-prop="children"
                @change="loadUnionOptions(form.courseId, '', '')"
              >
                <a-select-option v-for="item in courseOptions" :key="item.uuid" :value="item.uuid">
                  {{ item.courseName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="一类级别">
              <a-select allow-clear v-model="form.level1" @change="loadUnionOptions(form.courseId, form.level1, '')">
                <a-select-option v-for="item in unionOptions.level1" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="二类级别">
              <a-select allow-clear v-model="form.level2">
                <a-select-option v-for="item in unionOptions.level2" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="排课周期">
              <a-select allow-clear v-model="form.dayOfWeeks" mode="multiple">
                <a-select-option v-for="item in dayOfWeekOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="学生数量">
              <a-select allow-clear v-model="form.formClassCapacityStatus">
                <a-select-option
                  v-for="item in scheduleOptions.formClassCapacityStatus"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="剩余课节">
              <a-select allow-clear v-model="form.courseScheduleRemain">
                <a-select-option value=""> 全部 </a-select-option>
                <a-select-option value="0"> 等于0 </a-select-option>
                <a-select-option value="1"> 大于0 </a-select-option>
                <a-select-option value="5"> 大于0 小于5 </a-select-option>
                <a-select-option value="10"> 大于0 小于10 </a-select-option>
                <a-select-option value="20"> 大于0 小于20 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="上课平台">
              <a-select allow-clear v-model="form.platform">
                <a-select-option v-for="item in platformsArr" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="班主任">
              <a-select
                allow-clear
                show-search
                v-model="form.ownerId"
                @search="loadClassAdminOptions"
                :filter-option="filterOption"
                option-filter-prop="children"
              >
                <a-select-option v-for="item in classAdminList" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="getListData(1)">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetForm">重置</a-button>
              <a-button style="margin-left: 10px" type="primary" @click="onNewClass()"> 新建班级 </a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="stable-wrap">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >
        <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
        >
          <a-select
            v-ant-ref="(c) => (searchInput = c)"
            show-search
            placeholder="所在时区"
            option-filter-prop="children"
            :filter-option="filterOptionTimezone"
            v-model="form.convertTimezone"
            style="width: 220px; margin-bottom: 8px; display: block"
          >
            <a-select-option :value="i.value" v-for="(i, index) of timezones" :key="index">
              {{ `${i.label}(${i.value})` }}
            </a-select-option>
          </a-select>
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 40px"
            @click="() => handleSearchTimezone(selectedKeys, confirm, column.dataIndex)"
          >
            确定
          </a-button>
          <a-button size="small" style="width: 90px" @click="() => handleTimezone(clearFilters)"> 重置</a-button>
        </div>

        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="swap"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />

        <span slot="className" slot-scope="text, record">
          <router-link :to="{ path: '/workoutSchedule/classDetail', query: { classId: record.uuid } }">
            {{ text }}</router-link
          >
        </span>
        <span slot="timezone" slot-scope="text">{{ getTimezoneName(text) }}</span>
        <span slot="classType" slot-scope="text">{{ getDdlLabel(scheduleOptions.classType, text) }}</span>
        <span slot="platform" slot-scope="text">{{ getDdlLabel(platformsArr, text) }}</span>
        <span slot="courseType" slot-scope="text">{{ getDdlLabel(scheduleOptions.courseType, text) }}</span>
        <span slot="classStatus" slot-scope="text">{{ getDdlLabel(scheduleOptions.classStatus, text) }}</span>
        <span slot="currentCourseSchedule" slot-scope="text, record">
          <a-tooltip placement="topLeft" :key="record.uuid">
            <template slot="title">
              <span>{{ (text && text.scheduleName) || '' }}</span>
              <span v-if="text && text.sectionNameStudent">{{ `(${text.sectionNameStudent})` }}</span>
            </template>
            <div style="color: #1890ff" class="text-container">
              <span>{{ (text && text.scheduleName) || '' }}</span>
              <span v-if="text && text.sectionNameStudent">{{ `(${text.sectionNameStudent})` }}</span>
            </div>
          </a-tooltip>

          <div style="color: green">
            {{
              `${
                text
                  ? text.startDateTimeFormClassTimeZone +
                    initOptionsType(text.dayOfWeek, adjustWeekOptions) +
                    getDdlLabel(timezoneList, text.convertTimezone).substring(
                      0,
                      getDdlLabel(timezoneList, text.convertTimezone).indexOf(')') + 1,
                    )
                  : ''
              }`
            }}
          </div>

          <a-popover placement="bottom" v-if="text && text.teacherName">
            <template slot="content">
              <teacher-info
                :key="text && text.teacherId"
                :teacherId="text && text.teacherId"
                :subjectType="record.currentSectionData && record.currentSectionData.subjectType"
              ></teacher-info>
            </template>
            <div style="color: orange; cursor: pointer">{{ (text && text.teacherName) || '' }}</div>
          </a-popover>
        </span>
        <span slot="action" slot-scope="text, record" style="word-break: keep-all">
          <a v-if="record.classStatus !== 'COMPLETED' && record.authorized" @click="onEditClass(record)"> 编辑 </a>
          <a
            v-if="record.classStatus !== 'COMPLETED' && record.authorized"
            @click="completeCourse(record.uuid, record.className)"
          >
            结课
          </a>
          <a v-if="record.authorized" @click="deleteClass(record.uuid, record.className)"> 删除 </a>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import storage from 'store';
import { postReq, putReq, deleteReq, loadTimezoneList, loadClassAdminOptions } from '@/api/schedule';
import { dayOfWeekOptions } from '@/utils/const';
import { getDdlLabel } from '@/utils/util';
import BaseList from '@/components/BaseList/index.vue';
import { timezones } from '@/api/headTeacher';
import TeacherInfo from '@/views/schedule/teacherInfo';
import { getTimezoneName } from '@/utils/geoLocation';

const userInfo = storage.get('userInfo');
const roles = storage.get('roles');
const columns = [
  {
    title: '班级名称',
    dataIndex: 'className',
    width: 260,

    key: 'className',
    scopedSlots: { customRender: 'className' },
  },
  {
    title: '班级时区',
    dataIndex: 'timezone',
    key: 'timezone',
    scopedSlots: { customRender: 'timezone' },
  },
  {
    title: '上课平台',
    dataIndex: 'platform',
    key: 'platform',
    scopedSlots: { customRender: 'platform' },
  },
  {
    title: '班型',
    dataIndex: 'classType',
    key: 'classType',
    scopedSlots: { customRender: 'classType' },
  },
  {
    title: '课程类型',
    key: 'courseType',
    dataIndex: 'courseType',
    scopedSlots: { customRender: 'courseType' },
  },
  {
    title: '下一课信息',
    key: 'currentCourseSchedule',
    dataIndex: 'currentCourseSchedule',

    scopedSlots: {
      customRender: 'currentCourseSchedule',
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
    },
    onFilter: (value, record) => record.address.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => {
        //   this.searchInput.focus();
        // });
      }
    },
  },
  {
    title: '学生数量',
    key: 'studentCount',
    dataIndex: 'studentCount',
    customRender: (text, record, index) => `${record.studentCount}/${record.capacity}`,
  },
  {
    title: '班主任',
    key: 'data.headmasterName',
    dataIndex: 'data.headmasterName',
  },
  {
    title: '剩余课节',
    key: 'courseScheduleRemain',
    dataIndex: 'courseScheduleRemain',
  },
  {
    title: '状态',
    key: 'classStatus',
    dataIndex: 'classStatus',
    scopedSlots: { customRender: 'classStatus' },
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  extends: BaseList,
  name: 'workoutSchedule',
  components: {
    TeacherInfo,
  },
  data() {
    return {
      userInfo,
      getTimezoneName,
      adjustWeekOptions: [
        { label: '周一', value: 'MONDAY' },
        { label: '周二', value: 'TUESDAY' },
        { label: '周三', value: 'WEDNESDAY' },
        { label: '周四', value: 'THURSDAY' },
        { label: '周五', value: 'FRIDAY' },
        { label: '周六', value: 'SATURDAY' },
        { label: '周日', value: 'SUNDAY' },
      ],
      toggleTimezones: null,
      timezones: null,
      searchInput: null,
      // 高级搜索 展开/关闭
      advanced: false,
      getDdlLabel,
      scheduleOptions: {
        classType: [],
        courseType: [],
        classStatus: [],
        formClassCapacityStatus: [],
      },
      dayOfWeekOptions,
      classAdminList: [],
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      columns,
      timezoneList: [],
      searchUrl: `/api/admin/scheduling/form_class`,
      form: {
        classStatus: null,
        classType: null,
        className: null,
        courseSubject: userInfo.subject,
        courseType: null,
        courseId: null,
        level1: null,
        level2: null,
        startDate: null,
        dayOfWeeks: [],
        formClassCapacityStatus: null,
        courseScheduleRemain: null,
        convertTimezone: undefined,
        ownerId:
          userInfo.subject === 'CHINESE' ? (roles.includes('CLASS_ADMIN') ? userInfo.userID : undefined) : undefined,
      },
      courseOptions: [],
      unionOptions: {
        level1: [],
        level2: [],
      },
    };
  },
  computed: {
    platformsArr() {
      if (this.userInfo.roleArr.includes('CLASS_ADMIN')) {
        return [{ label: '悟空教室', value: 'TALK_CLOUD' }];
      }
      return [
        { label: 'Classin', value: 'CLASS_IN' },
        { label: '悟空教室', value: 'TALK_CLOUD' },
      ];
    },
  },
  activated() {
    console.log(333333);
    this.getListData(1);
  },

  created() {
    loadTimezoneList((res) => {
      this.timezoneList = res;
    });
    // 获取时区列表
    timezones().then((i) => {
      this.timezones = i.data.content;
    });

    // 代表从组件模块点击他的学生
    if (this.form.ownerId) {
      this.loadClassAdminOptions(userInfo.fullName);
    }
    this.loadCourseOptions();
  },
  methods: {
    getDdlLabelSub(ddlOptions, value) {
      if (!ddlOptions || ddlOptions.length < 1 || !value) {
        return '';
      }
      for (let i = 0; i < ddlOptions.length; i++) {
        if (ddlOptions[i].value == value) {
          return `${ddlOptions[i].label}(${ddlOptions[i].value})`;
        }
      }
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    handleSearchTimezone(selectedKeys, confirm, dataIndex) {
      confirm();
      this.getListData(1);
    },
    handleTimezone(clearFilters) {
      clearFilters();
      this.form.convertTimezone = undefined;
      this.getListData(1);
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    filterOptionTimezone(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    loadClassAdminOptions(name) {
      const params = {
        name,
      };
      loadClassAdminOptions(params).then((res) => {
        this.classAdminList = res.data.content;
      });
    },
    loadUnionOptions(courseId, level1, level2) {
      this.form.level1 = level1;
      this.form.level2 = level2;
      postReq('/api/admin/scheduling/course_structure/search', {
        subjectType: this.getSubjectType(courseId),
        level1,
        level2,
      }).then((res) => {
        if (level1) {
          this.unionOptions.level2 = res.data.content;
        } else {
          this.unionOptions.level1 = res.data.content;
        }
      });
    },
    loadCourseOptions() {
      postReq('/api/admin/scheduling/course/search', { subject: this.form.courseSubject, shelfStatus: ['ON'] }).then(
        (res) => {
          this.courseOptions = res.data.content;
        },
      );
    },
    subjectChange() {
      this.loadCourseOptions();
    },
    getSubjectType(courseId) {
      const courseResult = this.courseOptions.find((x) => x.uuid === courseId);
      const subjectType = courseResult ? courseResult.subjectType.nodeContent.value : '';
      return subjectType;
    },
    completeCourse(id, className) {
      const _this = this;
      this.$confirmAnt({
        title: '结课',
        content: `确认已完成 ${className} 吗?`,
        okText: '确认',
        cancelText: '取消',
        onOk() {
          _this.loading = true;
          putReq(`/api/admin/scheduling/form_class/complete/${id}`, {})
            .then((res) => {
              _this.getListData();
            })
            .finally(() => {
              _this.loading = false;
            });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    deleteClass(id, className) {
      const _this = this;
      this.$confirmAnt({
        title: '删除',
        content: `确认删除 ${className} 吗?`,
        okText: '确认',
        cancelText: '取消',
        onOk() {
          _this.loading = true;
          deleteReq(`${_this.searchUrl}/${id}`, {})
            .then((res) => {
              _this.getListData();
            })
            .finally(() => {
              _this.loading = false;
            });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    onEditClass(record) {
      this.$router.push({
        path: '/workoutSchedule/classEdit',
        query: { id: record.uuid, classAdminNameSub: record.data.headmasterName },
      });
    },
    onNewClass(record) {
      this.$router.push({
        path: '/workoutSchedule/classEdit',
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 0px;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.table-page-search-wrapper .ant-form-inline .ant-form-item {
  margin-bottom: 14px;
}
.stable-wrap {
  background-color: #fff;
  padding: 16px 20px;
}
.text-container {
  width: 200px;
  white-space: nowrap; /* 防止文本折行 */
  overflow: hidden; /* 隐藏超出容器宽度的文本 */
  text-overflow: ellipsis; /* 显示省略号 */
}
</style>
