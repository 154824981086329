<template>
  <div class="teacher-popover">
    <div class="top">
      <img :src="item.contentUrl || Avatar" alt="" style="border-radius: 50%" />
      <div class="top-right">
        <div class="name-wrap">
          <div class="name">
            <span>{{ item.fullName }}</span>
            <img v-if="item.gender === 'M'" src="" alt="" />
            <img v-if="item.gender === 'F'" src="" alt="" />
          </div>
        </div>

        <div class="teaching-style">
          <li v-for="styleItem in item.teachingStyles" :key="styleItem">#{{ styleItem }}</li>
        </div>
      </div>
    </div>
    <a-divider dashed style="color: red">本页信息仅供内部查看，禁止对外传播</a-divider>

    <div class="middle">
      <span class="info-title">教师简介</span>
      <p v-if="item.desc">{{ item.desc }}</p>
      <p v-else>暂无内容</p>
      <span class="info-title">教学理念</span>
      <p v-if="item.teachingPhilosophy">{{ item.teachingPhilosophy }}</p>
      <p v-else>暂无内容</p>
    </div>
    <div class="bottom">
      <p>
        <span><b>工作状态：</b>{{ getDdlLabel(scheduleOptions.workStatus, item.workStatus) }}</span
        ><span class="item-container"><b>授课语言：</b>{{ (item.languages && item.languages.join(',')) || '' }}</span>
      </p>
      <p>
        <span class="item-container"><b>教龄 (年)：</b>{{ item.teachingAge }}</span
        ><span><b>最高学历：</b>{{ item.degree }}</span>
      </p>
      <p>
        <span class="item-container"
          ><b>可授课程：</b>{{ (item.courseStructures && item.courseStructures.join(',')) || '' }}</span
        >
      </p>
      <div class="modal-btn-group">
        <!-- <a :href="item.assessmentVideoRecord" target="_blank" v-if="item.assessmentVideoRecord">
                          <a-button type="primary" > 视 频 </a-button>
                        </a> -->
        <a-popover :getPopupContainer="getPopupContainer">
          <template slot="content">
            <div @click="hrefIntroVideo(item.videoUrl)" class="intro-item">介绍视频</div>
            <div @click="hrefVideoRecord(item.data.classRoomId, item.assessmentVideoRecord)" class="intro-item">
              考核视频
            </div>
          </template>
          <a-button type="primary">视 频</a-button>
        </a-popover>
        <a-popover :getPopupContainer="getPopupContainer">
          <template slot="content">
            <div v-if="liveUrlList.length > 0">
              <div v-for="(item, index) in liveUrlList" class="play-item" :key="index" @click="hrefLiveUrl(item)">
                {{ item.scheduleName }}
              </div>
            </div>
            <div v-else>暂无课节回放</div>
          </template>
          <a-button type="primary" @mouseenter="getPlayLiveUrl(item)">回 放 </a-button>
        </a-popover>

        <a-button type="primary" @click="toClassCalendar(item)"> 课 表 </a-button>
        <a-button type="primary" @click="lookTeacherPoster(item)">简 历 </a-button>
      </div>
    </div>
    <a-modal
      :zIndex="1031"
      title="老师海报"
      :okButtonProps="{
        props: {
          loading: iconLoading,
        },
      }"
      :visible="visibleReport"
      @ok="handleSubmit"
      okText="生成海报"
      cancelText="关闭"
      width="426px"
      @cancel="onCancelReport"
      destroyOnClose
    >
      <div class="modal-height">
        <div>
          <div id="studyReportTemplate2TC" ref="studyReportTemplateTC" style="zoom: 0.5; width: 750px">
            <!-- <TeacherPosterTemplate v-if="teacherInfo" style=" position:relative; top: 0;" v-bind="teacherInfo" /> -->
            <teacher-poster :info="JSON.stringify(teacherInfo || {})" />
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal title="介绍视频" :zIndex="1031" :footer="null" :visible="showVideo" @cancel="closeVideo">
      <div>
        <video :src="item.videoUrl" style="width: 472px" controls="controls"></video>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { TeacherPosterTemplate } from '@wk/tch-sc';
import html2canvas from 'html2canvas';
import { queryTeacherInfo, loadScheduleOptions, getVideoRecord } from '@/api/schedule';
import { getDdlLabel } from '@/utils/util';
import { getTeacherSchedule } from '@/api/headTeacher';
import Avatar from '@/assets/avatar.png';
import { getReplayLink } from '@/api/common';

export default {
  name: 'TeacherInfo',
  props: ['teacherId', 'subjectType'],
  components: { TeacherPosterTemplate },

  data() {
    return {
      showVideo: false,
      Avatar,
      iconLoading: false,
      teacherInfo: null,
      item: {},
      visibleReport: false,
      scheduleOptions: {
        workStatus: [],
      },
      liveUrlList: [],
    };
  },
  methods: {
    lookTeacherPoster() {
      this.visibleReport = true;
    },
    getPopupContainer(ele) {
      // 解决hover里面悬浮，外面 的弹窗关闭问题
      return ele.parentElement;
    },
    getDdlLabel,
    getPlayLiveUrl(item) {
      this.liveUrlList = [];
      const params = {
        teacherId: this.teacherId,
        subjectType: this.subjectType,
      };
      getTeacherSchedule(params).then((res) => {
        this.liveUrlList = res.data.content;
      });
    },

    // 获取回放课节
    async hrefLiveUrl(item) {
      try {
        const { data } = await getReplayLink(item.uuid);
        const videoInfo = data?.[0];
        if (videoInfo?.type === 'HTML_URL') {
          window.open(videoInfo?.link);
        } else {
          window.open(`/lessonReplay?courseScheduleId=${item.uuid}`, '_blank');
        }
      } catch {
        window.open(`/lessonReplay?courseScheduleId=${item.uuid}`, '_blank');
      }
    },
    async hrefVideoRecord(roomId, url) {
      if (!roomId && !url) {
        this.$message.warning('该老师暂无考核视频');
        return;
      }
      if (!roomId && url) {
        window.open(url, '_blank');
        return;
      }

      const res = await getVideoRecord(roomId);
      if (res?.data?.length) {
        window.open(`/lessonReplayPlayback?roomId=${roomId}`, '_blank');
      } else {
        this.$message.warning('回放生成中');
      }

      // if (url) {
      //   window.open(url, '_blank');
      // } else {
      //
      // }
    },
    hrefIntroVideo(url) {
      if (url) {
        this.showVideo = true;
      } else {
        this.$message.warning('该老师暂无介绍视频');
      }
    },
    closeVideo() {
      this.showVideo = false;
    },
    queryTeacherInfo() {
      const params = {
        teacherId: this.teacherId,
      };
      queryTeacherInfo(params).then((res) => {
        this.item = res.data.content;
        this.teacherInfo = { ...this.item, ...this.item.data, avatar: this.item.contentUrl };
        console.log(this.item);
        // this.teacherInfo = {
        //     fullName:this.item.fullName,
        //     avatar:this.item.contentUrl,
        //     teachingStyles:this.item.teachingStyles,
        //     eduBackground:this.item.eduBackground,
        //     desc:this.item.desc,
        //     certificates:this.item.certificates,
        //     workExperience:this.item.workExperience,
        //     subject:this.item.subject,
        //   };
      });
    },
    toClassCalendar(item) {
      console.log(item);
      const routeUrl = this.$router.resolve({
        path: '/teacherCalendar',
        query: { teacherName: item.fullName, teacherId: item.uuid, timezone: item.timezone },
      });
      window.open(routeUrl.href, '_blank');
    },
    handleSubmit() {
      this.iconLoading = true;
      this.$nextTick(() => {
        html2canvas(this.$refs.studyReportTemplateTC, {
          onclone: (cloneDocument) => {
            const dom = cloneDocument.getElementById('studyReportTemplate2TC');
            dom.style.zoom = 1;
          },
          useCORS: true, // 【重要】开启跨域配置
          backgroundColor: 'transparent',
          //  scale:2, //按比例增加分辨率 (2=双倍).
          //  dpi: window.devicePixelRatio * 2, //设备像素比
        }).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png', 1.0);

          // 复制成功

          canvas.toBlob(async (blob) => {
            const data = [
              new ClipboardItem({
                [blob.type]: blob,
              }),
            ];
            await navigator.clipboard.write(data);
            this.$message.success('复制报告成功');
            this.iconLoading = false;
            this.visibleReport = false;
          });
        });
      });
    },
    onCancelReport() {
      this.visibleReport = false;
    },
  },
  mounted() {
    loadScheduleOptions((res) => {
      this.scheduleOptions = res;
    });
    this.queryTeacherInfo();
  },
};
</script>

<style lang="less" scoped>
.play-item {
  color: rgba(0, 0, 0, 0.85);
  padding-bottom: 5px;
  cursor: pointer;
  &:hover {
    color: #04cb94;
  }
}
.ant-btn-primary {
  background-color: #04cb94;
}
.teacher-popover {
  width: 45vw;
  p {
    display: flex;
    margin-bottom: 10px;
    span {
      flex: 1;
    }
  }
  .ant-divider-inner-text {
    font-size: 12px;
  }
  .top,
  .middle,
  .bottom {
    padding-left: 27px;
    padding-right: 27px;
  }
  .top {
    display: flex;
    padding-top: 10px;
    margin-bottom: 20px;
    img {
      width: 90px;
      height: 90px;
    }
    .top-right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      span {
        margin: 5px;
      }
      .name-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          display: flex;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
          }
          span:first-child {
            font-size: 28px;
            font-weight: bold;
          }
        }
        .btn-group {
          text-align: right;
        }
        button {
          margin-left: 10px;
        }
      }
      .teaching-style {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        li {
          padding: 2px 4px;
          color: #666666;
          background-color: #f7f7f7;
          border-radius: 6px;
          margin-right: 10px;
          margin-top: 5px;
        }
      }
    }
  }
  .middle {
    .info-title {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    p {
      color: #666666;
      text-indent: 20px;
      margin: 10px 0px 20px 0px;
      display: block;
    }
  }
  .bottom {
    color: #333333;
    padding-bottom: 20px;
    b {
      font-weight: normal;
      color: #999999;
    }
  }
  .modal-btn-group {
    display: flex;
    justify-content: flex-end;
    button {
      margin: 10px 0 0 10px;
    }
  }
}
.intro-item {
  cursor: pointer;
  margin-bottom: 8px;
}
.modal-height {
  max-height: 568px;
  overflow-y: auto;
}
#studyReportTemplate2TC {
  background: transparent;
}
</style>
